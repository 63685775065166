// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* login.css */

body {
  background: radial-gradient(circle, #b0f3f7, #2cdeac);
  font-family: 'Arial', sans-serif;
}

.form2 {
  width: 400px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.form2 label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.form2 input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-sizing: border-box;
}

.form2 button {
  background-color: #007bff;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.form2 button:hover {
  background-color: #0056b3;
}

.form2 a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
  margin-top: 15px;
  display: inline-block;
}

.form2 a:hover {
  color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/Register/reg.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,qDAAqD;EACrD,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* login.css */\n\nbody {\n  background: radial-gradient(circle, #b0f3f7, #2cdeac);\n  font-family: 'Arial', sans-serif;\n}\n\n.form2 {\n  width: 400px;\n  margin: 50px auto;\n  padding: 30px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n  text-align: center;\n}\n\n.form2 label {\n  display: block;\n  margin-bottom: 8px;\n  color: #555;\n}\n\n.form2 input {\n  width: 100%;\n  padding: 12px;\n  margin-bottom: 20px;\n  border: 1px solid #ced4da;\n  border-radius: 5px;\n  box-sizing: border-box;\n}\n\n.form2 button {\n  background-color: #007bff;\n  color: #fff;\n  padding: 12px 20px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  transition: background-color 0.3s ease;\n}\n\n.form2 button:hover {\n  background-color: #0056b3;\n}\n\n.form2 a {\n  text-decoration: none;\n  color: #007bff;\n  font-size: 14px;\n  margin-top: 15px;\n  display: inline-block;\n}\n\n.form2 a:hover {\n  color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
