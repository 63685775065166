// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* login.css */

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: radial-gradient(circle, #b0f3f7, #2cdeac);
}

.form2 {
  width: 350px;
  padding: 30px;
  background: linear-gradient(to right, hsl(182, 88%, 57%), #41ffdc);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  color: #333;
}

input {
  background-color: rgb(196, 243, 255);
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.log {
  background-color: #3498db;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.log:hover {
  background-color: #2980b9;
}

a {
  text-decoration: none;
  color: #3498db;
  margin-top: 15px;
  font-size: 14px;
}

a:hover {
  color: #2980b9;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/login.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,qDAAqD;AACvD;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kEAAkE;EAClE,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* login.css */\n\n.App {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background: radial-gradient(circle, #b0f3f7, #2cdeac);\n}\n\n.form2 {\n  width: 350px;\n  padding: 30px;\n  background: linear-gradient(to right, hsl(182, 88%, 57%), #41ffdc);\n  border-radius: 10px;\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n}\n\nform {\n  display: flex;\n  flex-direction: column;\n}\n\nlabel {\n  margin-bottom: 8px;\n  color: #333;\n}\n\ninput {\n  background-color: rgb(196, 243, 255);\n  padding: 12px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  font-size: 14px;\n}\n\n.log {\n  background-color: #3498db;\n  color: #fff;\n  padding: 12px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n  transition: background-color 0.3s ease;\n}\n\n.log:hover {\n  background-color: #2980b9;\n}\n\na {\n  text-decoration: none;\n  color: #3498db;\n  margin-top: 15px;\n  font-size: 14px;\n}\n\na:hover {\n  color: #2980b9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
