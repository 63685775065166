// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
    background-color: #4caf50; /* Green background color */
    height: 30px; /* Set the height of the progress bar */
    text-align: center; /* Center the text within the progress bar */
    line-height: 30px; /* Set the line height to vertically center the text */
    color: white; /* Text color */
    font-weight: bold; /* Make the text bold */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pro.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,2BAA2B;IACtD,YAAY,EAAE,uCAAuC;IACrD,kBAAkB,EAAE,4CAA4C;IAChE,iBAAiB,EAAE,sDAAsD;IACzE,YAAY,EAAE,eAAe;IAC7B,iBAAiB,EAAE,uBAAuB;EAC5C","sourcesContent":[".progress-bar {\n    background-color: #4caf50; /* Green background color */\n    height: 30px; /* Set the height of the progress bar */\n    text-align: center; /* Center the text within the progress bar */\n    line-height: 30px; /* Set the line height to vertically center the text */\n    color: white; /* Text color */\n    font-weight: bold; /* Make the text bold */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
