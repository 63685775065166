import React from "react";
import './index.css'
const Index=()=>{



    return(
        <>
        
<img className='indexpic' src="2.png"></img>
        
        </>





    )






}

export default Index;